<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-card elevation="0" outlined>
      <v-card-text>
        <v-form v-model="valid" ref="selfcheckoutform">
          <v-row>
            <v-col cols="12" sm="6" lg="4">
              <v-text-field @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="adminBarcode"
                :label="$t('generic.lang_adminBarcode')" outlined
                :rules="[adminBarcodeRule]" :hint="$t('generic.lang_adminBarcodePrefixHint')">
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" lg="4">
              <v-checkbox :hide-details="true" v-model="customerCanVoid"
                :label="$t('generic.lang_customerCanVoid')"></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
          <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
            :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
            :layout="touchKeyboard.layout" :options="touchKeyboard.options" class="internalWidthExpanded" />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="success" :disabled="!valid" @click="updateSettings" :loading="loading">
          {{ $t("generic.lang_save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns"

export default {
  name: "SelfCheckoutSettingsComponent",
  mixins: [mixin],
  data() {
    return {
      loading: false,
      valid: false,
      customerCanVoid: false,
      adminBarcode: null
    }
  },
  methods: {
    adminBarcodeRule(v) {
      return !v || v.startsWith('RSPW$') || this.$t('generic.lang_adminBarcodePrefixError')
    },
    getSettings() {
      this.loading = true;

      this.axios.get(ENDPOINTS.SETTINGS.SELFCHECKOUT.GET).then((res) => {
        this.customerCanVoid = res.data.customerCanVoid;
        this.adminBarcode = res.data.adminBarcode;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    updateSettings() {

      if (!this.$refs.selfcheckoutform.validate()) {
        return;
      }

      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.SELFCHECKOUT.UPDATE, {
        customerCanVoid: this.customerCanVoid,
        adminBarcode: this.adminBarcode
      }).then((res) => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t("generic.lang_actionSuccessful")
          });
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t("generic.lang_anErrorOccurred")
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getSettings();
  }
}
</script>
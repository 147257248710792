<template>
  <div>
    <page-title :icon=icon :heading="$t('generic.lang_selfCheckoutSettings')" :subheading="$t('generic.lang_selfCheckoutSettings')"></page-title>
    <div class="app-main__inner">
      <SelfCheckoutSettingsComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import SelfCheckoutSettingsComponent from "../../../components/settings/cashier/SelfCheckoutSettingsComponent.vue";
export default {
  name: "SelfCheckoutSettings",
  components: {SelfCheckoutSettingsComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-tools icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>